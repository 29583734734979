
import { defineComponent } from "vue-demi";
import { mapGetters } from "vuex";
import { mainSocketFollow } from "@/socket";
import { initialChart } from "@/store/common/chart";

import CommonSidebar from "@/components/common/sidebar/index.vue";
import CommonMobileSidebar from "@/components/common/sidebar/mobile/index.vue";
import CommonDropElement from "@/components/common/drop/DropElement.vue";
import CommonHeader from "@/components/common/header/index.vue";
import CommonModal from "@/components/common/modal/index.vue";
import CommonCalendar from "@/components/common/calendar/index.vue";
import GeneralNotification from "@/components/common/notification/General.vue";
import CommonSupport from "@/components/common/support/index.vue";
import { Promocode } from "@/interfaces/promocode/promocode.dto";

export default defineComponent({
  name: "main-layout",
  props: ["empty"],
  data() {
    return {
      componentKey: 0,
    };
  },
  async created() {
    if (this.$route.meta.requiredAuth) {
      mainSocketFollow(this);
      this.socket.on("connect", () => {
        mainSocketFollow(this);
      });
    }
  },
  mounted() {
    this.showNews();
    this.onEmits();
    window.addEventListener("resize", this.resize);
    initialChart();
  },
  unmounted() {
    this.offEmits();
    window.removeEventListener("resize", this.resize);
  },
  methods: {
    showNews() {
      // const viewed = localStorage.getItem("world_payment");
      // if (viewed) return;

      // this.$store.commit('createModal', { id: 'tariff-payment-update', data: {} });
      // localStorage.setItem("world_payment", "true");
    },
    resize() {
      this.$store.commit("resizeWidth", window.innerWidth);
    },
    // Emitter
    onEmits() {
      this.emitter.on("user-logout", this.userLogoutEmitter);
      this.emitter.on("user-replenishment", this.userReplenishmentEmitter);
      this.emitter.on("user-withdraw", this.userWithdrawEmitter);
      this.emitter.on("channel-delete", this.channerDeleteEmitter);
      this.emitter.on("channel-promocode", this.channelPromocodeEmitter);
      this.emitter.on("botpay-withdraw", this.botpayWithdrawEmitter);
    },
    offEmits() {
      this.emitter.off("user-logout", this.userLogoutEmitter);
      this.emitter.off("user-replenishment", this.userReplenishmentEmitter);
      this.emitter.off("user-withdraw", this.userWithdrawEmitter);
      this.emitter.off("channel-delete", this.channerDeleteEmitter);
      this.emitter.off("channel-promocode", this.channelPromocodeEmitter);
      this.emitter.off("botpay-withdraw", this.botpayWithdrawEmitter);
    },
    userLogoutEmitter() {
      this.$store.dispatch("logoutAction");
    },
    botpayWithdrawEmitter() {
      this.$store.dispatch("getBotpayPaymentMethod");
    },
    userReplenishmentEmitter(payment: { data: string; action: string }) {
      const link = document.createElement("a");
      link.href = payment.data;
      link.click();
    },
    userWithdrawEmitter(balance: { data: number; action: string }) {
      this.$store.commit("updateUserBalance", {
        type: "partner",
        value: balance.data,
      });
    },
    channerDeleteEmitter() {
      this.$store.commit("createGeneralNotification", {
        message: "Сообщество удалено!",
        type: "static",
      });
      this.$router.push("/channel");
      if (this.$route.meta.id === "channel-list") location.reload();
    },
    channelPromocodeEmitter(data: { data: Promocode.Dto; action: string }) {
      setTimeout(() => {
        this.$store.commit("createModal", {
          id: "promocode-view",
          data: data.data,
        });
      }, 100);
    },
  },
  computed: {
    ...mapGetters(["user", "modal", "drop", "calendar", "innerWidth"]),
    isLayout() {
      return !!this.$route.meta.layout;
    },
    isDesktop() {
      return this.innerWidth > 740;
    },
    layout() {
      return this.$route.meta.layout + "-layout";
    },
  },
  watch: {
    async $route(to, from) {
      if (
        (to.meta.id === from.meta.id && to.meta.id === "channel-main") ||
        (to.meta.id === "channel-create" && from.meta.id === "channel-list")
      )
        this.componentKey += 1;
    },
  },
  components: {
    CommonSupport,
    CommonSidebar,
    CommonHeader,
    CommonModal,
    CommonMobileSidebar,
    CommonDropElement,
    CommonCalendar,
    GeneralNotification,
  },
});
