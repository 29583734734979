import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, Transition as _Transition, withCtx as _withCtx, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page flex-column" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_common_sidebar = _resolveComponent("common-sidebar")!
  const _component_common_mobile_sidebar = _resolveComponent("common-mobile-sidebar")!
  const _component_common_header = _resolveComponent("common-header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_common_modal = _resolveComponent("common-modal")!
  const _component_common_drop_element = _resolveComponent("common-drop-element")!
  const _component_common_calendar = _resolveComponent("common-calendar")!
  const _component_general_notification = _resolveComponent("general-notification")!
  const _component_common_support = _resolveComponent("common-support")!

  return (_openBlock(), _createElementBlock("main", {
    class: _normalizeClass(["flex", { mobile: !_ctx.isDesktop }])
  }, [
    (_ctx.isDesktop)
      ? (_openBlock(), _createBlock(_component_common_sidebar, {
          key: 0,
          user: _ctx.user
        }, null, 8, ["user"]))
      : (_openBlock(), _createBlock(_component_common_mobile_sidebar, {
          key: 1,
          user: _ctx.user
        }, null, 8, ["user"])),
    _createElementVNode("section", _hoisted_1, [
      _createVNode(_component_common_header, {
        class: "page--header",
        user: _ctx.user
      }, null, 8, ["user"]),
      (_openBlock(), _createBlock(_component_router_view, {
        class: "page--container",
        key: _ctx.componentKey
      }, {
        default: _withCtx(({ Component }) => [
          _createVNode(_Transition, {
            name: "route",
            mode: "out-in"
          }, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
                class: _normalizeClass(_ctx.$route.meta.id),
                user: _ctx.user
              }, null, 8, ["class", "user"]))
            ]),
            _: 2
          }, 1024)
        ]),
        _: 1
      }))
    ]),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.modal)
          ? (_openBlock(), _createBlock(_component_common_modal, {
              key: 0,
              modal: _ctx.modal
            }, null, 8, ["modal"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.drop)
          ? (_openBlock(), _createBlock(_component_common_drop_element, {
              key: 0,
              drop: _ctx.drop
            }, null, 8, ["drop"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.calendar)
          ? (_openBlock(), _createBlock(_component_common_calendar, {
              key: 0,
              calendar: _ctx.calendar
            }, null, 8, ["calendar"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_general_notification),
    (_ctx.isDesktop)
      ? (_openBlock(), _createBlock(_component_common_support, { key: 2 }))
      : _createCommentVNode("", true)
  ], 2))
}