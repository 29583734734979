import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4748a964"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "support" }
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_support_icon = _resolveComponent("support-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", {
      href: _ctx.link,
      target: "_blank",
      class: "support--container flex-center common cursor fz12"
    }, [
      _createVNode(_component_support_icon, { color: "#fff" })
    ], 8, _hoisted_2)
  ]))
}