
import { defineComponent } from "vue-demi";
import { SupportIcon } from "@/plugins/icons";

export default defineComponent({
  name: "CommonSupport",
  computed: {
    link() {
      return `https://t.me/BotpaySupportBot`;
    },
  },
  components: {
    SupportIcon,
  },
});
